// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---plugins-gatsby-theme-sky-lite-src-templates-home-js": () => import("../plugins/gatsby-theme-sky-lite/src/templates/home.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-templates-home-js" */),
  "component---plugins-gatsby-theme-sky-lite-src-templates-tag-js": () => import("../plugins/gatsby-theme-sky-lite/src/templates/tag.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-templates-tag-js" */),
  "component---plugins-gatsby-theme-sky-lite-src-templates-results-tag-js": () => import("../plugins/gatsby-theme-sky-lite/src/templates/resultsTag.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-templates-results-tag-js" */),
  "component---plugins-gatsby-theme-sky-lite-src-templates-page-js": () => import("../plugins/gatsby-theme-sky-lite/src/templates/page.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-templates-page-js" */),
  "component---plugins-gatsby-theme-sky-lite-src-templates-post-js": () => import("../plugins/gatsby-theme-sky-lite/src/templates/post.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-templates-post-js" */),
  "component---plugins-gatsby-theme-sky-lite-src-templates-results-all-js": () => import("../plugins/gatsby-theme-sky-lite/src/templates/resultsAll.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-templates-results-all-js" */),
  "component---plugins-gatsby-theme-sky-lite-src-pages-404-js": () => import("../plugins/gatsby-theme-sky-lite/src/pages/404.js" /* webpackChunkName: "component---plugins-gatsby-theme-sky-lite-src-pages-404-js" */)
}

